<template>
    <div class="vg-plan-interactif">
        <div v-if="isLoadingBackgroundLayer" class="loader-bar">
            <span>{{$t("chargement-1-sur-3")}}</span>
        </div>
        <div v-if="isLoadingLayerPieces" class="loader-bar">
            <span>{{$t("chargement-2-sur-3")}}</span>
        </div>
        <div v-else-if="isLoadingLayerEquipements" class="loader-bar">
            <span>{{$t("chargement-3-sur-3")}}</span>
        </div>
        <div v-if="fondDePlan" class="plan-interactif">
            <vg-plan-interactif-panel v-if="etage"
                v-show="showLeftPanel"
                :isModeEdition="isModeEdition"
                class="left-panel"
                @file-imported="handleImportNewPieces" />
            <vg-leaflet
                class="main-panel"
                :idMap="'plan-interactif'"
                :url="fondDePlan.link"
                :attribution="etage.path"
                :layers="getLayers"
                @loaded-layer="onLoadedLayer"
                @loaded-background-layer="onLoadedBackgroundLayer"
                @loaded-layers="onLoadedLayers"
                @map-click="onMapClick"
                @map-position="$emit('map-position', $event)"
                @drop-external-element="onDropExternalElement"/>
        </div>
        <div v-else-if="isLoadingBackgroundLayer" class="loader-container">
            <span>{{$t("chargement-plan-interactif")}}</span>
        </div>
        <div v-else-if="!hasFondDePlan && !isLoadingBackgroundLayer" class="main-uploader-container">
            <span>{{$t("aucun-fond-de-plan")}}</span>
            <vg-files-uploader v-if="VgFilesMixins_getTagFondDePlan && can('PLAN_INTERACTIF.UPDATE')"
                :inputLabel="$t('importer-fichier-jpg-png')"
                :uidToUpload="etage.uid"
                :defaultType="'plan-interactif'"
                :fileType="'image/*'"
                :tagsList="tags"
                :defaultTag="VgFilesMixins_getTagFondDePlan"
                @upload-done="onUploadFondDePlanDone" />
        </div>
        <vg-equipement-form v-if="showEquipementForm"
            :idLieu="focusedPieceMarker.properties.id"
            :reloadAfterSave="false"
            @close="showEquipementForm=false;$emit('close-equipement-form');"
            @save="onSaveEquipementDansPiece"/>
        <vg-lieu-form v-if="showUpdateLieuForm"
            v-model="focusedPieceMarker.properties"
            :id="focusedPieceMarker.properties.id"
            skipVueXStorage
            @close="showUpdateLieuForm=false;$emit('close-update-lieu');"
            @updated="onUpdateLieu"/>
    </div>
</template>

<script>

import VgLeaflet from "src/components/Vg/Map/VgLeaflet.vue";
import PiecesLayerControl from "src/components/Vg/PlanInteractif/LayerControl/PiecesLayerControl.vue";
import EquipementsLayerControl from "src/components/Vg/PlanInteractif/LayerControl/EquipementsLayerControl.vue";
import PieceMarker from 'src/components/Vg/PlanInteractif/Marker/PieceMarker.vue';
import PopupPieceMarker from 'src/components/Vg/PlanInteractif/Popup/PopupPieceMarker.vue';
import EquipementMarker from 'src/components/Vg/PlanInteractif/Marker/EquipementMarker.vue';
import PopupEquipementMarker from 'src/components/Vg/PlanInteractif/Popup/PopupEquipementMarker.vue';
import VgPlanInteractifPanel from 'src/components/Vg/PlanInteractif/VgPlanInteractifPanel.vue';
import VgEquipementForm from "src/components/Vg/Forms/VgEquipementForm.vue";
import VgLieuForm from "src/components/Vg/Forms/VgLieuForm.vue";
import VgFilesUploader from 'src/components/Vg/Files/VgFilesUploader.vue';

import PlanInteractifMixins from "src/mixins/PlanInteractifMixins.js";
import LieuMixins from "src/mixins/LieuMixins.js";
import equipementsMixins from "src/mixins/equipementsMixins.js";
import CategorieMixins from "src/mixins/CategorieMixins.js";
import LibelServicesMixins from "src/mixins/LibelServicesMixins.js";

import Metadatas from "src/services/Metadatas.js";
import { mapGetters } from 'vuex';

export default {
    name: "vg-plan-interactif",
    components: {
        VgLeaflet,
        VgPlanInteractifPanel,
        VgFilesUploader,
        VgEquipementForm,
        VgLieuForm,
    },
    mixins:[ LieuMixins, equipementsMixins, PlanInteractifMixins, CategorieMixins, LibelServicesMixins ],
    props:{
        etage:{
            type: Object,
            default: function(){
                return null;
            }
        },
        isModeEdition: {
            type: Boolean,
            default: false
        },
        showLeftPanel: {
            type: Boolean,
            default: true
        }
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "piece-existe-deja": "Ce libellé pièce ou ce qrCode de pièce existe déjà.",
            "aucun-fond-de-plan": "Aucun fond de plan sur ce niveau",
            "chargement-plan-interactif": "Chargement du plan interactif...",
            "chargement-1-sur-3": "Chargement du fond de plan... 1/3",
            "chargement-2-sur-3": "Chargement des pièces... 2/3",
            "chargement-3-sur-3": "Chargement des équipements... 3/3",
            "alert-inserted-marker-error-duplique": "Cette pièce existe déjà.",
            "chargement": "Chargement...",
            "uploader-fond-de-carte": "Importer un fond de carte",
            "importer-fichier-jpg-png": "Importer une image JPEG / PNG"
        }
    }
},
    data:function() {
        return {
            piecesLayer: null,
            piecesZoomOutLayer: null,
            equipementsLayer: null,
            equipementsZoomOutLayer: null,
            isLoadingBackgroundLayer: false,
            isLoadingLayerPieces: false,
            isLoadingLayerEquipements: false,
            hasFondDePlan: false,
            showEquipementForm: false,
            showUpdateLieuForm: false,
            importPiecesMockDatas:[
                {
                    libel_lieu: "Salle 1",
                    codeUn: "S1",
                    coordX: 627,
                    coordY: 314
                }
            ]
        }
    },
    watch:{
        etage: {
            handler: function(newetage){
                // console.log("WATCH NEWETAGE", newetage);
            },
            deep: true
        },
        isModeEdition: {
            handler: function(value){
                if(value) this.generateDraftLayers();
                else this.generateNotDraftLayers();
            }
        },
        markerEquipementVersion: {
            handler: function(version){
                if(this.isModeEdition) this.generateDraftLayers();
                else this.generateNotDraftLayers();
            }
        }
    },
    mounted:function(){
        this.initDatas();
        this.fetchServices();
        this.fetchCategories();
    },
    methods: {
        fetchCategories: function(){
            let metadatasCategories = new Metadatas();
            metadatasCategories.setFilters({
                isGe: {attr:"c.isGe", value: "1", action: "equals"}
            });
            this.CategorieMixins_getCategories(metadatasCategories, {_stored: true}).then((datas)=>{
                
            });
        },
        fetchServices: function(){
            let metadatas = new Metadatas();
            this.LibelServicesMixins_getLibelServices(metadatas, {_stored: true}).then((datas)=>{
                
            });
        },
        /**
        * @param Object représente un fichier
        */
        onUploadFondDePlanDone: function(plan){
            // console.log("onUploadFondDePlanDone", plan);
            this.initDatas();
        },
        /**
        * @param datas {event: $event, latlng: {lat:..., lng:...}}
        */
        onDropExternalElement: function(datas){
            let event = datas.event;
            let latlng = datas.latlng;
            // console.log("DROP EXTERNAL ELEMENT", event, latlng);
            if(event.dataTransfer.getData("equipement")){ // DROP UN EQUIPEMENT
                let equipement = JSON.parse(event.dataTransfer.getData("equipement"));
                // @TODO create equipement marker IE add geojson feature dans geojson equipements draft
                this.addFeatureInGeoJsonEquipementsDraft(equipement, latlng);
            }else if(event.dataTransfer.getData("piece")){ // DROP UNE PIECE
                let piece =  JSON.parse(event.dataTransfer.getData("piece"));
                // @TODO create pièce marker IE add geojson feature dans geojson pieces draft
                this.addFeatureInGeoJsonPiecesDraft(piece, latlng);
            }
        },
        /**
        * @param Object equipement
        * @param Object latlng {lat:..., lng:...}
        */
        addFeatureInGeoJsonEquipementsDraft: function(equipement, latlng){
            this.equipementsLayer.geoJson.features.push(this.PlanInteractif_newGeoJsonFeature(equipement, latlng));
            this.$store.dispatch("PlanInteractifStore/setGeoJsonEquipementsDraft", this.equipementsLayer.geoJson);
            this.$store.dispatch("PlanInteractifStore/setHasEquipementsDraftChanged", true);
        },
        /**
        * @param Object piece
        * @param Object latlng {lat:..., lng:...}
        */
        addFeatureInGeoJsonPiecesDraft: function(piece, latlng){
            this.piecesLayer.geoJson.features.push(this.PlanInteractif_newGeoJsonFeature(piece, latlng));
            this.$store.dispatch("PlanInteractifStore/setGeoJsonPiecesDraft", this.piecesLayer.geoJson);
            this.$store.dispatch("PlanInteractifStore/setHasPiecesDraftChanged", true);
        },
        pieceMarkerNotAlreadyExist: function(pieceMarker){
            console.log({pieceMarker});
            let pieceMarkerIndexInFeatures = this.piecesLayer.geoJson.features.findIndex((f)=>f.properties.libel_lieu==pieceMarker.libel_lieu || f.properties.codeUn==pieceMarker.codeUn);
            let pieceMarkerInPieces = this.pieces.findIndex((p)=>p.libel_lieu==pieceMarker.libel_lieu || p.codeUn==pieceMarker.codeUn);
            return pieceMarkerIndexInFeatures==-1 && pieceMarkerInPieces==-1;
        },
        onMapClick: function(e){
            console.log("VGPI onMapClick", e, this.piecesLayer, this.newPieceMarker);
            if(this.isModeEdition && this.newPieceMarker){
                let notAlreadyExist = this.pieceMarkerNotAlreadyExist(this.newPieceMarker);
                if(notAlreadyExist){
                    let newPieceMarker = Object.assign({}, this.newPieceMarker, {
                        coordX: e.latlng.lng,
                        coordY: e.latlng.lat
                    });
                    this.piecesLayer.geoJson.features.push(this.PlanInteractif_convertDataToFeature(newPieceMarker));
                    this.$store.dispatch("PlanInteractifStore/setGeoJsonPiecesDraft", this.PlanInteractif_geoJsonDeepCopy(this.piecesLayer.geoJson));
                    this.$store.dispatch("PlanInteractifStore/setNewPieceMarker", null);
                    this.$store.dispatch("PlanInteractifStore/setHasPiecesDraftChanged", true);
                }else{
                    alert(this.$t("piece-existe-deja"));
                }
            }else{
                // @TODO nothing
            }
        },
        onDragPieceMarker: function(feature, latlng){
            let indexFeatureInPiecesLayer = this.piecesLayer.geoJson.features.findIndex((f)=>feature.properties.id?f.properties.id==feature.properties.id:f.properties.libel_lieu==feature.properties.libel_lieu);
            if(indexFeatureInPiecesLayer!=-1){
                this.piecesLayer.geoJson.features[indexFeatureInPiecesLayer] = this.PlanInteractif_updateFeaturePosition(feature, latlng);
                this.$store.dispatch("PlanInteractifStore/setGeoJsonPiecesDraft", this.PlanInteractif_geoJsonDeepCopy(this.piecesLayer.geoJson));
                this.$store.dispatch("PlanInteractifStore/setHasPiecesDraftChanged", true);
            }
        },
        onDragEquipementMarker: function(feature, latlng){
            let indexFeatureInEquipementsLayer = this.equipementsLayer.geoJson.features.findIndex((f)=>feature.properties.id?f.properties.id==feature.properties.id:f.properties.libel_equipement==feature.properties.libel_equipement && f.properties.qrCode==feature.properties.qrCode);
            if(indexFeatureInEquipementsLayer!=-1){
                this.equipementsLayer.geoJson.features[indexFeatureInEquipementsLayer] = this.PlanInteractif_updateFeaturePosition(feature, latlng);
                this.$store.dispatch("PlanInteractifStore/setGeoJsonEquipementsDraft", this.PlanInteractif_geoJsonDeepCopy(this.equipementsLayer.geoJson));
                this.$store.dispatch("PlanInteractifStore/setHasEquipementsDraftChanged", true);
            }
        },
        onLoadedLayer: function(datas){
            // console.log("LOADED LAYER", datas.name, datas.layer);
            if(datas.name=="pieces") this.isLoadingLayerPieces = false;
            if(datas.name=="equipements") this.isLoadingLayerEquipements = false;
        },
        onLoadedBackgroundLayer: function(){
            // console.log("LOADED BACKGROUND LAYER");
            this.isLoadingBackgroundLayer = false;
        },
        onLoadedLayers: function(){
            // console.log("LOADED BACKGROUND LAYER");
            this.isLoadingLayerPieces = false;
            this.isLoadingLayerEquipements = false;
            this.$emit("map-initialized");
        },
        getGeoJsonFileContent: function(geoJsonFile, storeMethod=null){
            return new Promise((resolve, reject) => {
                if(geoJsonFile && geoJsonFile.link){
                    this.VgFilesMixins_getFileContentFromUrl(geoJsonFile.link, true).then((geoJson)=>{
                        // console.log("FILE CONTENT", storeMethod, geoJson);
                        if(storeMethod) this.$store.dispatch("PlanInteractifStore/"+storeMethod, geoJson);
                        resolve(geoJson);
                    });
                } else {
                    let emptyGeoJson = this.PlanInteractif_geoJsonVide;
                    this.$store.dispatch("PlanInteractifStore/"+storeMethod, emptyGeoJson);
                    resolve(emptyGeoJson);
                }
            });
        },
        associeGeoJsonPieces: function(){
            let pieces = this.pieces;
            let indexPiece = null;
            if(this.geoJsonPieces){
                let geoJsonPiecesCopy = this.PlanInteractif_geoJsonDeepCopy(this.geoJsonPieces);
                geoJsonPiecesCopy.features.forEach((feature, index)=>{
                    indexPiece = pieces.findIndex((p)=>p.id==feature.properties.id);
                    if(indexPiece!=-1) geoJsonPiecesCopy.features[index].properties = pieces[indexPiece];
                });
                this.$store.dispatch("PlanInteractifStore/setGeoJsonPieces", geoJsonPiecesCopy);
            }
            if(this.geoJsonPiecesDraft){
                let geoJsonPiecesCopy = this.PlanInteractif_geoJsonDeepCopy(this.geoJsonPiecesDraft);
                geoJsonPiecesCopy.features.forEach((feature, index)=>{
                    indexPiece = pieces.findIndex((p)=>p.id==feature.properties.id);
                    if(indexPiece!=-1) geoJsonPiecesCopy.features[index].properties = pieces[indexPiece];
                });
                this.$store.dispatch("PlanInteractifStore/setGeoJsonPiecesDraft", geoJsonPiecesCopy);
            }
        },
        handleImportNewPieces: function(piecesFileCsv){
            this.PlanInteractif_importCsv(piecesFileCsv).then((pieces)=>{
                let geoJsonPiecesCopy = this.PlanInteractif_geoJsonDeepCopy(this.geoJsonPiecesDraft);
                pieces.forEach((piece)=>{
                    piece.idLieuParent_id = this.etage.id;
                    geoJsonPiecesCopy.features.push(this.PlanInteractif_convertDataToFeature(piece));
                });
                this.$store.dispatch("PlanInteractifStore/setGeoJsonPiecesDraft", geoJsonPiecesCopy);
                this.$store.dispatch("PlanInteractifStore/setHasPiecesDraftChanged", true);
                this.generateDraftLayers();
            });

        },
        associeGeoJsonEquipements: function(){
            let equipements = this.equipements;
            let indexEquipement = null;
            if(this.geoJsonEquipements){
                let geoJsonEquipementsCopy = this.PlanInteractif_geoJsonDeepCopy(this.geoJsonEquipements);
                geoJsonEquipementsCopy.features.forEach((feature, index)=>{
                    indexEquipement = equipements.findIndex((eq)=>eq.id==feature.properties.id);
                    if(indexEquipement!=-1) geoJsonEquipementsCopy.features[index].properties = equipements[indexEquipement];
                });
                this.$store.dispatch("PlanInteractifStore/setGeoJsonEquipements", geoJsonEquipementsCopy);
            }
            if(this.geoJsonEquipementsDraft){
                let geoJsonEquipementsCopy = this.PlanInteractif_geoJsonDeepCopy(this.geoJsonEquipementsDraft);
                geoJsonEquipementsCopy.features.forEach((feature, index)=>{
                    indexEquipement = equipements.findIndex((eq)=>eq.id==feature.properties.id);
                    if(indexEquipement!=-1) geoJsonEquipementsCopy.features[index].properties = equipements[indexEquipement];
                });
                this.$store.dispatch("PlanInteractifStore/setGeoJsonEquipementsDraft", geoJsonEquipementsCopy);
            }
        },
        generateNotDraftLayers: function(){
            let geoJsonPieces = this.getGeoJsonPieces();
            let geoJsonEquipements = this.getGeoJsonEquipements();
            this.piecesLayer = {
                name: "pieces",
                geoJson: geoJsonPieces,
                options: this.getPiecesLayerOptions,
                control: this.getPiecesLayerControl,
                _displayConditions: {
                    zoom: { min: 2.5, max: null }
                }
            };
            this.piecesZoomOutLayer = {
                name: "pieces",
                geoJson: geoJsonPieces,
                options: this.getPiecesZoomOutLayerOptions,
                control: this.getPiecesLayerControl,
                _displayConditions: {
                    zoom: { min: 0, max: 2.5 }
                }
            };
            this.equipementsLayer = {
                name: "equipements",
                geoJson: geoJsonEquipements,
                options: this.getEquipementsLayerOptions,
                control: this.getEquipementsLayerControl,
                _displayConditions: {
                    zoom: { min: 2.5, max: null }
                }
            };
            this.equipementsZoomOutLayer = {
                name: "equipements",
                geoJson: geoJsonEquipements,
                options: this.getEquipementsZoomOutLayerOptions,
                control: this.getEquipementsLayerControl,
                _displayConditions: {
                    zoom: { min: 0, max: 2.5 }
                }
            };
        },
        generateDraftLayers: function(){
            let geoJsonPiecesDraft = this.getGeoJsonPiecesDraft();
            let geoJsonEquipementsDraft = this.getGeoJsonEquipementsDraft();
            this.piecesLayer = {
                name: "pieces",
                geoJson: geoJsonPiecesDraft,
                options: this.getPiecesLayerOptions,
                control: this.getPiecesLayerControl,
                _displayConditions: {
                    zoom: { min: 2.5, max: null }
                }
            };
            this.piecesZoomOutLayer = {
                name: "pieces",
                geoJson: geoJsonPiecesDraft,
                options: this.getPiecesZoomOutLayerOptions,
                control: this.getPiecesLayerControl,
                _displayConditions: {
                    zoom: { min: 0, max: 2.5 }
                }
            };
            this.equipementsLayer = {
                name: "equipements",
                geoJson: geoJsonEquipementsDraft,
                options: this.getEquipementsLayerOptions,
                control: this.getEquipementsLayerControl,
                _displayConditions: {
                    zoom: { min: 2.5, max: null }
                }
            };
            this.equipementsZoomOutLayer = {
                name: "equipements",
                geoJson: geoJsonEquipementsDraft,
                options: this.getEquipementsZoomOutLayerOptions,
                control: this.getEquipementsLayerControl,
                _displayConditions: {
                    zoom: { min: 0, max: 2.5 }
                }
            };
        },
        fetchPieces: function(){
            return new Promise((resolve, reject) => {
                let metadatas = new Metadatas();
                let filtersPieces = {
                    idLieuParent_id: {attr: "l.idLieuParent_id", value: this.etage.id, action: "equals"},
                    type_lieu: {attr: "l.type_lieu", value: "Piece", action: "equals"}
                };
                metadatas.setFilters(filtersPieces);
                this.LieuMixins_getLieux(metadatas).then((datas) => {
                    // console.log("PIECES", datas);
                    this.$store.dispatch("LieuxStore/set", datas.lieux);
                    this.$store.dispatch("LieuxStore/setCounters", datas.metas.counters);
                    resolve(datas);
                });
            });
        },
        /**
        * @param Object filters {}
        */
        fetchEquipements: function(filters={}){
            return new Promise((resolve, reject) => {
                let metadatas = new Metadatas();
                let filtersEquipements = Object.assign({}, {
                    path: {attr: "l.path", value: this.etage.path, action: "start_with"},
                    isGroupEqp: {attr:"e.isGroupEqp", value:"1", action:"not_equals"}
                }, filters);
                metadatas.setFilters(filtersEquipements);
                this.equipementsMixins_getEquipements(metadatas).then((datas) => {
                    // console.log("EQUIPEMENTS", datas);
                    resolve(datas);
                });
            });
        },
        initDatas: function(){
            this.isLoadingBackgroundLayer = true;
            this.VgFilesMixins_init(this.etage.uid, "plan-interactif");
            let getFilesPromise = this.VgFilesMixins_getPlanInteractifFiles().then((files)=>{
                // console.log("GET PI FILES", files);
                this.$store.dispatch("PlanInteractifStore/setFiles", files);
                if(files["fond-de-plan"]){
                    this.hasFondDePlan = true;
                    this.isLoadingBackgroundLayer = false;
                    this.isLoadingLayerPieces = true;
                    this.isLoadingLayerEquipements = true;
                    Promise.all([
                        this.fetchPieces(),
                        this.fetchEquipements(),
                        this.getGeoJsonFileContent(files["geojson-pieces"], "setGeoJsonPieces"),
                        this.getGeoJsonFileContent(files["geojson-pieces-draft"], "setGeoJsonPiecesDraft"),
                        this.getGeoJsonFileContent(files["geojson-equipements"], "setGeoJsonEquipements"),
                        this.getGeoJsonFileContent(files["geojson-equipements-draft"], "setGeoJsonEquipementsDraft")
                    ]).then((values)=>{
                        this.associeGeoJsonPieces();
                        this.associeGeoJsonEquipements();
                        if(this.isModeEdition) this.generateDraftLayers();
                        else this.generateNotDraftLayers();
                    }).catch((e)=>{
                        // console.log("PROMISE ALL REJECTED", e);
                    });
                }else{
                    this.hasFondDePlan = false;
                    this.isLoadingBackgroundLayer = false;
                    this.isLoadingLayerPieces = false;
                    this.isLoadingLayerEquipements = false;
                }
            }).catch((e)=>{
                this.hasFondDePlan = false;
                this.isLoadingBackgroundLayer = false;
                this.isLoadingLayerPieces = false;
                this.isLoadingLayerEquipements = false;
            });
        },
        getGeoJsonPieces: function(){
            if(this.geoJsonPieces) return this.PlanInteractif_geoJsonDeepCopy(this.geoJsonPieces);
            else return this.PlanInteractif_geoJsonVide;
        },
        getGeoJsonPiecesDraft: function(){
            if(this.geoJsonPiecesDraft && this.geoJsonPiecesDraft.features.length!=0) return this.PlanInteractif_geoJsonDeepCopy(this.geoJsonPiecesDraft);
            else if(this.geoJsonPieces) return this.PlanInteractif_geoJsonDeepCopy(this.geoJsonPieces);
            else return this.PlanInteractif_geoJsonVide;
        },
        getGeoJsonEquipements: function(){
            if(this.geoJsonEquipements) return this.PlanInteractif_geoJsonDeepCopy(this.geoJsonEquipements);
            else return this.PlanInteractif_geoJsonVide;
        },
        getGeoJsonEquipementsDraft: function(){
            if(this.geoJsonEquipementsDraft && this.geoJsonEquipementsDraft.features.length!=0) return this.PlanInteractif_geoJsonDeepCopy(this.geoJsonEquipementsDraft);
            else if(this.geoJsonEquipements) return this.PlanInteractif_geoJsonDeepCopy(this.geoJsonEquipements);
            else return this.PlanInteractif_geoJsonVide;
        },
        onUpdatePieceMarker: function(data){
            let geoJsonPiecesCopy = this.PlanInteractif_geoJsonDeepCopy(this.geoJsonPiecesDraft);
            let indexPieceInGeoJson = geoJsonPiecesCopy.features.findIndex((feature)=>feature.properties.libel_lieu==data.lieu.libel_lieu && feature.properties.qrCode==data.lieu.qrCode);
            if(indexPieceInGeoJson!=-1) geoJsonPiecesCopy.features[indexPieceInGeoJson].properties = Object.assign({}, geoJsonPiecesCopy.features[indexPieceInGeoJson].properties, data.updatedLieu);
            this.$store.dispatch("PlanInteractifStore/setGeoJsonPiecesDraft", geoJsonPiecesCopy);
            this.$store.dispatch("PlanInteractifStore/setHasPiecesDraftChanged", true);
            this.generateDraftLayers();
        },
        onDeletePieceMarker: function(data){
            let geoJsonPiecesCopy = this.PlanInteractif_geoJsonDeepCopy(this.geoJsonPiecesDraft);
            let indexPieceInGeoJson = geoJsonPiecesCopy.features.findIndex((feature)=>feature.properties.libel_lieu==data.lieu.libel_lieu && feature.properties.qrCode==data.lieu.qrCode);
            if(indexPieceInGeoJson!=-1) geoJsonPiecesCopy.features.splice(indexPieceInGeoJson, 1);
            this.$store.dispatch("PlanInteractifStore/setGeoJsonPiecesDraft", geoJsonPiecesCopy);
            this.$store.dispatch("PlanInteractifStore/setHasPiecesDraftChanged", true);
            this.generateDraftLayers();
        },
        onUpdateEquipementMarker: function(data){
            // @WARNING not implemented && pas nécessaire l'user peut passer par zoom équipement pour update équipement (il n'existe pas de marker équipements non créés).
        },
        onDeleteEquipementMarker: function(data){
            // @WARNING not implemented
        },
        /**
        * @param Object data {lieu: <object lieu>, geoJsonPoint: ...}
        */
        onOpenEquipementForm: function(data){
            this.$store.dispatch("PlanInteractifStore/setFocusedPieceMarker", data.geoJsonPoint);
            this.$emit("open-equipement-form");
            this.showEquipementForm = true;
        },
        /**
        * @param Object data {lieu: <object lieu>, geoJsonPoint: ...}
        */
        onOpenUpdateLieu: function(data){
            this.$store.dispatch("PlanInteractifStore/setFocusedPieceMarker", data.geoJsonPoint);
            this.$emit("open-update-lieu");
            this.showUpdateLieuForm = true;
        },
        onUpdateLieu: function(lieu){
            this.$emit("close-update-lieu-form");
            this.showUpdateLieuForm = false;
            this.fetchPieces().then(()=>{
                this.associeGeoJsonPieces();
                this.associeGeoJsonEquipements();
                if(this.isModeEdition) this.generateDraftLayers();
                else this.generateNotDraftLayers();
            });
        },
        onSaveEquipementDansPiece: function(equipements){
            // @TODO create marker eq
            this.$emit("close-equipement-form");
            this.showEquipementForm = false;
            let latDecallage = 20;
            let latlng = {lat: this.focusedPieceMarker.geometry.coordinates[1], lng: this.focusedPieceMarker.geometry.coordinates[0] + 5};
            let equipementsFilters = {
                id: {attr:"e.id", value: equipements.map((eq)=>eq.id), action:"equals"}
            };
            this.fetchEquipements(equipementsFilters).then((equipements)=>{
                equipements.datas.forEach((equipement)=>{
                    latlng.lat -= latDecallage;
                    this.addFeatureInGeoJsonEquipementsDraft(equipement, latlng); // @WARNING equipement format !ok
                    this.$store.dispatch("PlanInteractifStore/setFocusedPieceMarker", null);
                });
                this.$store.dispatch("PlanInteractifStore/setHasEquipementsDraftChanged", true);
            });
        }
    },
    computed:{
        ...mapGetters({
            fondDePlan: "PlanInteractifStore/getFondDePlan",
            geoJsonPieces: "PlanInteractifStore/getGeoJsonPieces",
            geoJsonPiecesDraft: "PlanInteractifStore/getGeoJsonPiecesDraft",
            geoJsonEquipements: "PlanInteractifStore/getGeoJsonEquipements",
            geoJsonEquipementsDraft: "PlanInteractifStore/getGeoJsonEquipementsDraft",
            pieces: "LieuxStore/getCollection",
            categories: "CategoriesStore/getCollection",
            equipements: "EquipementsStore/getCollection",
            newPieceMarker: "PlanInteractifStore/getNewPieceMarker",
            dragEquipement: "PlanInteractifStore/getDragEquipement",
            focusedPieceMarker: "PlanInteractifStore/getFocusedPieceMarker",
            hasPiecesDraftChanged: "PlanInteractifStore/getHasPiecesDraftChanged",
            hasEquipementsDraftChanged: "PlanInteractifStore/getHasEquipementsDraftChanged",
            markerEquipementVersion: "PlanInteractifStore/getMarkerEquipementVersion"
        }),
        getLayers: function(){
            let layers = [];
            if(this.piecesLayer) layers.push(this.piecesLayer);
            if(this.piecesZoomOutLayer) layers.push(this.piecesZoomOutLayer);
            if(this.equipementsLayer) layers.push(this.equipementsLayer);
            if(this.equipementsZoomOutLayer) layers.push(this.equipementsZoomOutLayer);
            return layers;
        },
        getPiecesLayerOptions: function(){
            if(this.isModeEdition){
                return {
                    pointToLayer: (geoJsonPoint, latlng) => {
                        let icon = new L.DivIcon({
                            iconSize: new L.Point(),
                            html: new PieceMarker({propsData: {piece: geoJsonPoint.properties, geoJsonPoint: geoJsonPoint, pieces: this.pieces}}).$mount().$el
                        });
                        let marker = L.marker(latlng, {
                            "icon": icon,
                            "draggable": true
                        });
                        marker.bindPopup(
                            new PopupPieceMarker({propsData: {
                                    lieu: geoJsonPoint.properties, 
                                    geoJsonPoint: geoJsonPoint, 
                                    isCreationMode: true, 
                                    router: this.$router,
                                    pieces: this.pieces,
                                    services: this.services,
                                    categories: this.categories
                                }})
                                .$on("delete-marker", this.onDeletePieceMarker)
                                .$on("update-marker", this.onUpdatePieceMarker)
                                .$on("open-equipement-form", this.onOpenEquipementForm)
                                .$on("open-update-lieu", this.onOpenUpdateLieu)
                                .$mount().$el
                        );
                        marker.on("click", (e) => {
                            // console.log("GEOJSON POINT", geoJsonPoint);
                            marker.openPopup();
                        });
                        marker.on("dragend", (e) => {
                            // console.log("DRAG END", e);
                            this.onDragPieceMarker(geoJsonPoint, e.target._latlng);
                        });
                        return marker;
                    },
                };
            }else{
                return {
                    pointToLayer: (geoJsonPoint, latlng) => {
                        let icon = new L.DivIcon({
                            iconSize: new L.Point(),
                            html: new PieceMarker({propsData: {piece: geoJsonPoint.properties, geoJsonPoint: geoJsonPoint, pieces: this.pieces}}).$mount().$el
                        });
                        let marker = L.marker(latlng, {
                            "icon": icon,
                            "draggable": false
                        });
                        marker.bindPopup(
                            new PopupPieceMarker({propsData: {
                                lieu: geoJsonPoint.properties, 
                                geoJsonPoint: geoJsonPoint, 
                                isCreationMode: false, 
                                router: this.$router,
                                pieces: this.pieces,
                                services: this.services,
                                categories: this.categories
                            }})
                                .$mount().$el
                        );
                        marker.on("click", (e) => {
                            // console.log("GEOJSON POINT", geoJsonPoint);
                            marker.openPopup();
                        });
                        return marker;
                    },
                    filter:function(geoJsonFeature){
                        if(this.showUnplacedMarkers && geoJsonFeature.geometry.coordinates[0] <= 0) return false;
                        return true;
                    }
                };
            }
        },
        getPiecesZoomOutLayerOptions: function(){
            if(this.isModeEdition){
                return {
                    pointToLayer: (geoJsonPoint, latlng) => {
                        let icon = new L.DivIcon({
                            iconSize: new L.Point(),
                            html: new PieceMarker({propsData: {piece: geoJsonPoint.properties, geoJsonPoint: geoJsonPoint, zoomOut: true, pieces: this.pieces}}).$mount().$el
                        });
                        let marker = L.marker(latlng, {
                            "icon": icon,
                            "draggable": true
                        });
                        marker.bindPopup(
                            new PopupPieceMarker({propsData: {
                                lieu: geoJsonPoint.properties, 
                                geoJsonPoint: geoJsonPoint, 
                                isCreationMode: true, 
                                router: this.$router,
                                pieces: this.pieces,
                                services: this.services,
                                categories: this.categories
                            }})
                                .$on("delete-marker", this.onDeletePieceMarker)
                                .$on("update-marker", this.onUpdatePieceMarker)
                                .$on("open-equipement-form", this.onOpenEquipementForm)
                                .$on("open-update-lieu", this.onOpenUpdateLieu)
                                .$mount().$el
                        );
                        marker.on("click", (e) => {
                            // console.log("GEOJSON POINT", geoJsonPoint);
                            marker.openPopup();
                        });
                        marker.on("dragend", (e) => {
                            // console.log("DRAG END", e);
                            this.onDragPieceMarker(geoJsonPoint, e.target._latlng);
                        });
                        return marker;
                    },
                };
            }else{
                return {
                    pointToLayer: (geoJsonPoint, latlng) => {
                        let icon = new L.DivIcon({
                            iconSize: new L.Point(),
                            html: new PieceMarker({propsData: {piece: geoJsonPoint.properties, geoJsonPoint: geoJsonPoint, zoomOut: true, pieces: this.pieces}}).$mount().$el
                        });
                        let marker = L.marker(latlng, {
                            "icon": icon,
                            "draggable": false
                        });
                        marker.bindPopup(
                            new PopupPieceMarker({propsData: {
                                lieu: geoJsonPoint.properties, 
                                geoJsonPoint: geoJsonPoint, 
                                isCreationMode: false, 
                                router: this.$router,
                                pieces: this.pieces,
                                services: this.services,
                                categories: this.categories
                            }})
                                .$mount().$el
                        );
                        marker.on("click", (e) => {
                            // console.log("GEOJSON POINT", geoJsonPoint);
                            marker.openPopup();
                        });
                        return marker;
                    },
                    filter:function(geoJsonFeature){
                        if(this.showUnplacedMarkers && geoJsonFeature.geometry.coordinates[0] <= 0) return false;
                        return true;
                    }
                };
            }
        },
        getEquipementsLayerOptions: function(){
            if(this.isModeEdition){
                return {
                    pointToLayer: (geoJsonPoint, latlng) => {
                        let icon = new L.DivIcon({
                            iconSize: new L.Point(),
                            html: new EquipementMarker({propsData: {equipement: geoJsonPoint.properties, geoJsonPoint: geoJsonPoint, markerVersion: this.markerEquipementVersion}}).$mount().$el
                        });
                        let marker = L.marker(latlng, {
                            "icon": icon,
                            "draggable": true
                        });
                        marker.bindPopup(
                            new PopupEquipementMarker({propsData: {equipement: geoJsonPoint.properties, geoJsonPoint: geoJsonPoint, isCreationMode: true, router: this.$router}})
                                .$on("delete-marker", this.onDeleteEquipementMarker)
                                .$on("update-marker", this.onUpdateEquipementMarker)
                                .$mount().$el
                        );
                        marker.on("click", (e) => {
                            // console.log("GEOJSON POINT", geoJsonPoint);
                            marker.openPopup();
                        });
                        marker.on("dragend", (e) => {
                            // console.log("DRAG END", e);
                            this.onDragEquipementMarker(geoJsonPoint, e.target._latlng);
                        });
                        return marker;
                    }
                };
            }else{
                return {
                    pointToLayer: (geoJsonPoint, latlng) => {
                        let icon = new L.DivIcon({
                            iconSize: new L.Point(),
                            html: new EquipementMarker({propsData: {equipement: geoJsonPoint.properties, geoJsonPoint: geoJsonPoint, markerVersion: this.markerEquipementVersion}}).$mount().$el
                        });
                        let marker = L.marker(latlng, {
                            "icon": icon,
                            "draggable": false
                        });
                        marker.bindPopup(
                            new PopupEquipementMarker({propsData: {equipement: geoJsonPoint.properties, geoJsonPoint: geoJsonPoint, isCreationMode: false, router: this.$router}})
                                .$mount().$el
                        );
                        marker.on("click", (e) => {
                            // console.log("GEOJSON POINT", geoJsonPoint);
                            marker.openPopup();
                        });
                        return marker;
                    },
                };
            }
        },
        getEquipementsZoomOutLayerOptions: function(){
            if(this.isModeEdition){
                return {
                    pointToLayer: (geoJsonPoint, latlng) => {
                        let icon = new L.DivIcon({
                            iconSize: new L.Point(),
                            html: new EquipementMarker({propsData: {equipement: geoJsonPoint.properties, geoJsonPoint: geoJsonPoint, markerVersion: this.markerEquipementVersion, zoomOut: true}}).$mount().$el
                        });
                        let marker = L.marker(latlng, {
                            "icon": icon,
                            "draggable": true
                        });
                        marker.bindPopup(
                            new PopupEquipementMarker({propsData: {equipement: geoJsonPoint.properties, geoJsonPoint: geoJsonPoint, isCreationMode: true, router: this.$router}})
                                .$on("delete-marker", this.onDeleteEquipementMarker)
                                .$on("update-marker", this.onUpdateEquipementMarker)
                                .$mount().$el
                        );
                        marker.on("click", (e) => {
                            // console.log("GEOJSON POINT", geoJsonPoint);
                            marker.openPopup();
                        });
                        marker.on("dragend", (e) => {
                            // console.log("DRAG END", e);
                            this.onDragEquipementMarker(geoJsonPoint, e.target._latlng);
                        });
                        return marker;
                    }
                };
            }else{
                return {
                    pointToLayer: (geoJsonPoint, latlng) => {
                        let icon = new L.DivIcon({
                            iconSize: new L.Point(),
                            html: new EquipementMarker({propsData: {equipement: geoJsonPoint.properties, geoJsonPoint: geoJsonPoint, markerVersion: this.markerEquipementVersion, zoomOut: true}}).$mount().$el
                        });
                        let marker = L.marker(latlng, {
                            "icon": icon,
                            "draggable": false
                        });
                        marker.bindPopup(
                            new PopupEquipementMarker({propsData: {equipement: geoJsonPoint.properties, geoJsonPoint: geoJsonPoint, isCreationMode: false, router: this.$router}})
                                .$mount().$el
                        );
                        marker.on("click", (e) => {
                            // console.log("GEOJSON POINT", geoJsonPoint);
                            marker.openPopup();
                        });
                        return marker;
                    },
                };
            }
        },
        getPiecesLayerControl: function(){
            return new PiecesLayerControl({propsData:{}}).$mount().$el.innerHTML;
        },
        getEquipementsLayerControl: function(){
            return new EquipementsLayerControl({propsData:{}}).$mount().$el.innerHTML;
        },
    }
};
</script>
<style lang="scss" scoped>

$left-panel-width: 200px;
$main-panel-width: calc(100%-#{$left-panel-width});

.vg-plan-interactif{
    height: 100%;
    width: 100%;
    .plan-interactif{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        height: inherit;
        width: 100%;
        .left-panel{
            width: $left-panel-width;
        }
        .main-panel{
            width: $main-panel-width;
        }
    }
}
.loader-container{
    height: 100%;
    width: 100%;
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 0;
    left: 0;
}
.main-uploader-container{
    height: 100%;
    width: 100%;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    span{
        color: #02a7f0;
        font-size: 24px;
    }
}
.loader-bar{
    width: 100%;
    background-color: #f9b934;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
<style lang="scss">
.uploader-container{
    label{
        width: 100% !important;
    }
}
.leaflet-popup-close-button{
    display: none;
}
</style>
